.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CarouselCaption {
  background-color: rgba(255, 255, 255, 0.65);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.section-about .title-vertical {
  position: absolute;
  letter-spacing: 0;
  transform: rotate(-90deg);
  top: 50px;
  text-align: left;
  font-size: 1.8rem;
  width: 300px;
  font-weight: 700;
  text-align: right;
}

.about-img-box {
  padding-left: 1rem;
  position: relative;
}

.sinse-box {
  background-color: #7CCBFA;
  border-radius: 1px;
  padding: 1rem 2rem;
  position: absolute;
  left: 15px;
  bottom: 10px;
}

@media (min-width: 576px) {
  .sinse-box {
    padding: 1.5rem 2.5rem;
    bottom: 20px;
  }
}

@media (min-width: 768px) {
  .sinse-box {
    padding: 1.5rem 3rem;
  }
}

@media (min-width: 992px) {
  .sinse-box {
    padding: 3rem 4.5rem;
  }
}

.sinse-box .sinse-title {
  font-weight: 700;
  letter-spacing: 0.043em;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .sinse-box .sinse-title {
    font-size: 1.3rem;
  }
}

@media (min-width: 768px) {
  .sinse-box .sinse-title {
    font-size: 1.8rem;
  }
}

@media (min-width: 992px) {
  .sinse-box .sinse-title {
    font-size: 2.2rem;
  }
}

.sinse-box .sinse-title span {
  position: absolute;
}

.sinse-box .sinse-title span:after {
  content: " ";
  width: 35px;
  height: 2px;
  background-color: #000000;
  position: absolute;
  bottom: -15px;
}

.sinse-box p {
  margin-bottom: 0;
  font-size: 0.7rem;
}

@media (min-width: 576px) {
  .sinse-box p {
    font-size: 0.8rem;
  }
}

@media (min-width: 576px) {
  .sinse-box p {
    font-size: 1rem;
  }
}

p.TearFooter {
  display: flex;
  justify-content: space-between;
}
FooterIcon {
  margin: 5px;
}

span.tags{
  background-color: #EEEEEE;
}

.tagwork:hover {
  background-color: #7CCBFA;
  transition: 500ms;
}

a.tagwork {
  text-decoration: none;
  color: #000000;

}

ul li{margin-bottom:10px;}
.badge {
  --si-badge-padding-x: 0.6em;
  --si-badge-padding-y: 0.35em;
  --si-badge-font-size: 0.8125em;
  --si-badge-font-weight: 600;
  --si-badge-color: #fff;
  --si-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--si-badge-padding-y) var(--si-badge-padding-x);
  font-size: var(--si-badge-font-size);
  font-weight: var(--si-badge-font-weight);
  line-height: 1;
  color: var(--si-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--si-badge-border-radius);
}

.bg-secondary {
  --si-bg-opacity: 1;
  background-color: #f3f6ff !important;
}
.shadow-secondary {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(11, 15, 25, 0.15) !important;
}


.card {
  --si-card-spacer-y: 1.5rem;
  --si-card-spacer-x: 1.5rem;
  --si-card-title-spacer-y: 0.75rem;
  --si-card-border-width: 1px;
  --si-card-border-color: #e2e5f1;
  --si-card-border-radius: 0.5rem;
  --si-card-box-shadow: ;
  --si-card-inner-border-radius: calc(0.5rem - 1px);
  --si-card-cap-padding-y: 1.125rem;
  --si-card-cap-padding-x: 1.5rem;
  --si-card-cap-bg: transparent;
  --si-card-cap-color: ;
  --si-card-height: ;
  --si-card-color: ;
  --si-card-bg: #fff;
  --si-card-img-overlay-padding: 1.5rem;
  --si-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--si-card-height);
  word-wrap: break-word;
  background-color: var(--si-card-bg);
  background-clip: border-box;
  border: var(--si-card-border-width) solid var(--si-card-border-color);
  border-radius: var(--si-card-border-radius);
  box-shadow: var(--si-card-box-shadow);
}
.card-portfolio .card-img {
  transform: translateZ(0);
  overflow: hidden;
}

.card-portfolio .card-img > img {
  transition: transform 0.35s ease-in-out;
}
.card-portfolio:hover .sinse-box > h3 {
  display: block;
  transition: transform 0.35s;
}

.card-portfolio:hover .card-img > img {
  transform: scale(1.05);
}
.card-portfolio:hover .sinse-box > a {
  color: #6366f1;
}




.card-hover:not(.bg-transparent) {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.card-hover:not(.bg-transparent):hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
}
.card-hover:not(.bg-transparent).shadow-sm:hover {
  box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03) !important;
}
.card-hover:not(.bg-transparent).shadow:hover {
  box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(11, 15, 25, 0.1), 0 0.25rem 0.8125rem -0.125rem rgba(11, 15, 25, 0.06) !important;
}
.card-hover:not(.bg-transparent).border-primary:hover {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, 0.9) !important;
}

.card-hover {
  --si-card-icon-hover-color: #6366f1;
}
.card-hover .card-img-overlay {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 5;
}
.card-hover .card-icon {
  transition: color 0.3s ease-in-out;
}
.card-hover:hover .card-icon {
  color: var(--si-card-icon-hover-color) !important;
}
.card-hover:hover .card-img-overlay {
  opacity: 1;
}

.card-hover-primary {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.card-hover-primary.shadow-sm:hover, .card-hover-primary.shadow:hover, .card-hover-primary:hover {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, 0.9) !important;
}
.card-hover-primary .card-title,
.card-hover-primary .card-body,
.card-hover-primary .text-muted,
.card-hover-primary .text-dark,
.card-hover-primary .text-nav,
.card-hover-primary .text-primary,
.card-hover-primary h1, .card-hover-primary .h1, .card-hover-primary h2, .card-hover-primary .h2, .card-hover-primary h3, .card-hover-primary .h3,
.card-hover-primary h4, .card-hover-primary .h4, .card-hover-primary h5, .card-hover-primary .h5, .card-hover-primary h6, .card-hover-primary .h6 {
  transition: color 0.2s ease-in-out;
}
.card-hover-primary .border-top, .card-hover-primary .border-end,
.card-hover-primary .border-bottom, .card-hover-primary .border-start {
  transition: border-color 0.2s ease-in-out;
}
.card-hover-primary .bg-secondary {
  transition: background-color 0.2s ease-in-out;
}
.card-hover-primary:hover {
  border-color: var(--si-primary) !important;
  background-color: var(--si-primary) !important;
}
.card-title {
  color: #000000;
}

.card-hover:hover .card-title {
  color: #7CCBFA;
  transition: color 0.2s ease-in-out;
}
.card-hover-primary:hover .card-body {
  color: rgba(255, 255, 255, 0.7);
}
.card-hover-primary:hover .text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.card-hover-primary:hover .text-primary {
  color: #fff !important;
}
.card-hover-primary:hover .border-top, .card-hover-primary:hover .border-end,
.card-hover-primary:hover .border-bottom, .card-hover-primary:hover .border-start {
  border-color: rgba(255, 255, 255, 0.14) !important;
}
.card-hover-primary:hover .bg-secondary {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.card-hover-primary:hover h1, .card-hover-primary:hover .h1, .card-hover-primary:hover h2, .card-hover-primary:hover .h2, .card-hover-primary:hover h3, .card-hover-primary:hover .h3,
.card-hover-primary:hover h4, .card-hover-primary:hover .h4, .card-hover-primary:hover h5, .card-hover-primary:hover .h5, .card-hover-primary:hover h6, .card-hover-primary:hover .h6 {
  color: #fff !important;
}
.card-hover-primary:hover h1 > a, .card-hover-primary:hover .h1 > a, .card-hover-primary:hover h2 > a, .card-hover-primary:hover .h2 > a, .card-hover-primary:hover h3 > a, .card-hover-primary:hover .h3 > a,
.card-hover-primary:hover h4 > a, .card-hover-primary:hover .h4 > a, .card-hover-primary:hover h5 > a, .card-hover-primary:hover .h5 > a, .card-hover-primary:hover h6 > a, .card-hover-primary:hover .h6 > a {
  color: #fff !important;
}
.card-hover-primary:hover .text-dark:not(.badge.bg-white) {
  color: #fff !important;
}
.card-hover-primary:hover .text-nav:not(.badge.bg-white) {
  color: rgba(255, 255, 255, 0.85) !important;
}

.workcard {
  padding-bottom: 10px;
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
}

.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }




